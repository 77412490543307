var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: {
        icon: "mdi-text-box-check",
        title: "Impressão de Ocorrência do Ponto"
      }
    },
    [_c("sys-print-time-sheet-base")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }